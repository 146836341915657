<template>
  <div>
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" id="button-menu" class="navbar-toggle collapsed" data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div class="navbar-header">
            <a class="navbar-brand" href="#">
              <router-link to="/">
                <img class="logImage" alt="Brand" :src="logoImage">
              </router-link>
            </a>
          </div>
        </div>

        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav navbar-collapse">
            <li><a href="#">
              <router-link class="router-link" to="/">Home</router-link>
            </a></li>

            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Attend </a>
              <ul class="dropdown-menu">
                <li><a href="#">
                  <router-link class="router-link" to="/registration">Registration</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/accommodation">Accommodation
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/visa_information">Visa Information
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/travel_grants">Student Travel Grants
                  </router-link>
                </a></li>
              </ul>
            </li>

            <li><a href="#">
              <router-link class="router-link" to="/important-dates">Important Dates</router-link>
            </a></li>

            <!-- Start call for submission-->

            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Calls for Submission </a>
              <ul class="dropdown-menu">
                <li><a href="#">
                  <router-link class="router-link" to="/regular-papers">Regular Papers</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/multimedia-grand-challenge-proposals">Multimedia Grand Challenge
                    Proposals
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/multimedia-grand-challenge-submissions">Multimedia Grand Challenge
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/panel-proposals">Panel Proposals</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/workshop-proposals">Workshop Proposals</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/technical-demos-and-videos-program">Technical Demos and Videos
                    Program
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/tutorial-proposals">Tutorial Proposals</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/brave-new-ideas-proposals">Brave New Ideas Proposals
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/industrial-demo">Call For Industry Demonstrations
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/industry-expert-talks">Call for Industry Expert Talks
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/open-source-software-competition">Open Source Software
                    Competition
                  </router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/doctoral-cymposium">Doctoral Symposium</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/reproducibility-companion-papers">Reproducibility Companion
                    Papers
                  </router-link>
                </a></li>
              </ul>
            </li>
          

            <!-- End call for submission-->

            <!-- Start Guideline-->

            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Guidelines</a>
              <ul class="dropdown-menu">
                <li><a href="#">
                  <router-link class="router-link" to="/policies">Policies</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/reviewer_guidelines">Reviewer Guidelines</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/volunteer_ac">Call for Volunteer Area Chairs and Reviewers</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/major_differences">Major Differences: MM24 vs Previous</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/ac_guidelines">AC and SAC Guidelines</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/authors-advocate">Author’s Advocate
                  </router-link>
                </a></li>
              </ul>
            </li>

            <!-- End Guideline-->

            <!-- Start Program-->

            <li><a href="#">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Program</a>
              <ul class="dropdown-menu">
                <li><a href="#">
                  <router-link class="router-link" to="/keynotes">Conference Keynotes</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/accepted-list">Accepted Papers</router-link>
                </a></li>
                <!-- <li><a href="#">
                  <router-link class="router-link" to="/program-at-a-glance">Program at a Glance</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/full-draft-program">Full Technical Program</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/main-track-list">Main Track Paper List</router-link>
                </a></li> -->
                <li><a href="#">
                  <router-link class="router-link" to="/multimedia-grand-challenge-submissions">Multimedia Grand Challenge</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/workshop-papers">Workshop</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/tutorials">Conference Tutorials</router-link>
                </a></li>
                <!-- <li><a href="#">
                  <router-link class="router-link" to="/panel">Conference Panels</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/awards">Awards</router-link>
                </a></li> -->
              </ul>
            </a></li>

            <!-- End Program-->
            
            <!-- Start Attend-->

            <!-- <li><a href="#">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Attend</a>
              <ul class="dropdown-menu">
                
                <li><a href="#">
                  <router-link class="router-link" to="/registration">Registration</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/attendee-guide">Attendee Guide</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/app-guide">App Guide</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/travel-information">Travel Information</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/conference-venue">Conference Venue</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/author-instruction">Author Instruction</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/student-travel-grants">Student Travel Grants</router-link>
                </a></li>
                
                <li><a href="#">
                  <router-link class="router-link" to="/sigmm-carer-awards">SIGMM Carer Awards</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/recommended-accommodation">Accommodations</router-link>
                </a></li>
                
              </ul>
            </a></li> -->

            <!-- End Attend-->

            <!-- Start Organization-->

            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Organisation</a>
              <ul class="dropdown-menu">
                <li><a href="#">
                  <router-link class="router-link" to="/organisation-committee">Organisation Committee</router-link>
                </a></li>
                <!-- <li><a href="#">
                  <router-link class="router-link" to="/reviewer-guidelines">Reviewer Guidelines</router-link>
                </a></li> -->
              </ul>
            </li>

            <!-- End Organization-->

            <!--            <li><a href="#">-->
            <!--              <router-link class="router-link" to="/organization-committee">Organization</router-link>-->
            <!--            </a></li>-->

            <!--            <li><a class="last" href="#">-->
            <!--              <router-link class="router-link" to="/sponsor">Sponsor</router-link>-->
            <!--            </a></li>-->


            <!-- Start Sponsor-->

            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Sponsorship</a>
              <ul class="dropdown-menu">
                <!-- <li><a href="#">
                  <router-link class="router-link" to="/sponsors">Sponsorship</router-link>
                </a></li> -->
                <li><a href="#">
                  <router-link class="router-link" to="/call_for_sponsor_aus">Call for Sponsors</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/our_sponsors">Our Sponsors</router-link>
                </a></li>
              </ul>
            </li>

            <!-- End Sponsor-->


<!--            <li><a class="last disable" href="#">-->
<!--              Sponsor-->
<!--            </a></li>-->


            <!--            <li class="separated"><a href="#">/</a></li>-->
            <!--            <li class="dropdown">-->
            <!--              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"-->
            <!--                 aria-expanded="false">Dropdown <span class="caret"></span></a>-->
            <!--              <ul class="dropdown-menu">-->
            <!--                <li><a href="#">Action</a></li>-->
            <!--                <li><a href="#">Another action</a></li>-->
            <!--                <li><a href="#">Something else here</a></li>-->
            <!--                <li role="separator" class="divider"></li>-->
            <!--                <li><a href="#">Separated link</a></li>-->
            <!--                <li role="separator" class="divider"></li>-->
            <!--                <li><a href="#">One more separated link</a></li>-->
            <!--              </ul>-->
            <!--            </li>-->
            <!-- Start Destination-->

            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Destination</a>
              <ul class="dropdown-menu">
                <li><a href="#">
                  <router-link class="router-link" to="/explore_melbourne">Explore Melbourne</router-link>
                </a></li>
                <li><a href="#">
                  <router-link class="router-link" to="/visit_victoria">Visit Victoria</router-link>
                </a></li>
              </ul>
            </li>

            <!-- End Destination-->

            <!-- Start Contact-->

            <li><a href="#">
              <router-link class="router-link" to="/contact">Contact Us</router-link>
            </a></li>

            <!-- End Contact-->
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// $(document).ready(function(){
//   $(document).off('click.bs.dropdown.data-api');
// });

export default {
  name: "herder",
  data() {
    return {
      // logoImage: require("@/assets/acmmm2021_logo.png")
      // logoImage: require("@/assets/images/ACMMM_mel_logo_small.png")
      logoImage: require("@/assets/images/mm_logo_nobg.png")
      // logoImage: require("@/assets/images/logo_acm.png")
    }
  },
  mounted() {
    window.onload = () => {
      $(".router-link").on("click", function () {
        $('#button-menu').click()
      })
    }
  }
}
</script>

<style scoped>

@media screen and (min-width: 750px) {
  .nav > li:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    background: #FFFFFF;
    border-radius: 0rem 0rem 1.3rem 1.3rem;
    opacity: 0.9;
    /*border: 0.1rem solid #0054B0;*/
    /*border: 0.1rem solid #08b48c;*/
    /* border: 0.1rem solid #05cedd; */
    border: 0.1rem solid black;
    font-family: "Roboto", sans-serif;
  }

  .nav a:hover {
    font-weight: bold;
  }

  .dropdown-menu .router-link {
    color: #666666 !important;
  }

  .dropdown-menu .router-link:hover {
    /*color: #0054B0 !important;*/
    /*color: #08b48c !important;*/
    /* color: #05cedd !important; */
    color: black !important;
    
  }

  .dropdown-menu .router-link:hover::before {
    margin-left: -10px;
    padding-right: 5px;
    content: "|";
  }

  .dropdown-menu .router-link:before {
    content: "";
  }

  .collapse {
    margin-left: 18rem;
  }

}

.last:after {
  content: "" !important;
}

.nav > li > a:after {
  content: "/";
  /*color: #0054B0;*/
  /*color: #08b48c;*/
  /* color: #05cedd; */
  color: black;
  margin-left: 15px;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}


.navbar-default {
  background-color: white;
  border-color: white;
  /* background-color: black;
  border-color: black; */
  margin-top: 13px;
  margin-bottom: 13px;
}

.container-fluid {
  width: 112rem;
  margin: 0 auto;
}

@media screen and (min-width: 750px) {
  * {
    padding-left: 0;
    margin-left: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .logImage {
    height: 7.8rem;
  }

  .navbar-default {
    height: 8rem;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  #bs-example-navbar-collapse-1 {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .container-fluid {
    width: 95%;
  }
}

@media (min-width: 750px) and (max-width: 1150px) {
  .navbar-header {
    display: none;
  }

  .collapse {
    margin-left: 0;
  }
}

@media screen  and (max-width: 750px) {
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #FFFFFF;
    /* border-color: black; */
    
  }

  .logImage {
    height: 45px;
  }

  .nav > li > a:after {
    content: "";
  }

  .nav > li > a {
    padding-left: 0;
  }

  .navbar-collapse {
    margin-left: 0;
  }

  .collapse {
    margin-left: 0;
  }

  .container-fluid {
    margin-left: 10px;
  }

  .navbar-right > li > a:after {
    content: "";
  }

  #bs-example-navbar-collapse-1, #bs-example-navbar-collapse-1 * {
    padding: 0;
    margin: 0;
  }

  .nav > li {
    text-align: left;
    margin-left: 10px !important;
    padding-top: 10px !important;
  }

  .router-link {
    width: 100%;
  }

  .dropdown-menu {
    transition: .3s ease !important;
  }

  .dropdown-menu li {
    text-align: left;
    padding: 8px !important;
    border-bottom: 1px #e5e5e5 solid !important;
  }

  .navbar-header {
    padding-left: 15px !important;
  }

  .container-fluid {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .dropdown-menu {
    margin-top: 10px !important;
    /*border-top: 3px solid #0054B0 !important;*/
    /*border-top: 3px solid #08b48c !important;*/
    /* border-top: 3px solid #05cedd !important; */
    border-top: 3px solid black !important;
    width: 120% !important;
  }
}

a {
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  /*color: #0054B0 !important;*/
  /*color: #08b48c !important;*/
  /* color: #05cedd !important; */
  color: black !important;
  
}

.nav > li > a {
  padding-right: 0 !important;
}

.separated a {
  padding-left: 0;
  padding-right: 0;
}

.router-link {
  text-decoration: none;
}

.dropdown-toggle {
  background-color: #fff !important;
  /* background-color: black !important; */
}

.disable {
  color: gray !important;
  cursor: default;
}

.disable:hover {
  font-weight: 400 !important;
}
</style>
